import React from 'react'
import './Values.css'
const Values = () => (
    < >
      <div className="landing-page1-valuesimg">
        <img alt="Binary0037511" src="/external/values.png"  className='image-values'/>
        {/* <img alt="Binary0027511" src="/external/binary0027511-uf8-300w.png" className="landing-page1-binary002" />
        <img alt="Binary0017511" src="/external/binary0017511-t19-600w.png" className="landing-page1-binary001" />
        <img alt="Binary0047511" src="/external/binary0047511-aycm-200h.png" className="landing-page1-binary004" /> */}
      </div>
      <div className="landing-page1-frame7">
        <span className="landing-page1-text30">
          <span className="landing-page1-text31">Our Values</span>
          <ul className="landing-page1-text30">
            <li>Maximal Utility and access</li>
            <li>Net Productive outcome</li>
            <li>Responsible development</li>
        </ul>
        </span>
      </div>
    </>
  );
  
  export default Values;