import React from 'react'
import './Header.css'
const Header = ({sections}) => {
  const { headerRef, heroRef, productsRef, objectiveRef, valuesRef, footerRef  } = sections;
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };
  return(
    < >
      <img alt="logo7550" src="/external/logo7550-jlxe-200h.png" className="landing-page1-logo" />
      <span className="landing-page1-text12">
        <span onClick={() => scrollToSection(productsRef)} >Products </span>
       <span onClick={() => scrollToSection(objectiveRef)}>Company</span>
       <span onClick={() => scrollToSection(footerRef)}> Contact</span>
      </span>
    </>
  )
};
  
  export default Header;
