import React from 'react';
import './Footer.css';
import SubscribeForm from './SubscribeForm';

const Footer = () => (
    < >
        <div className="landing-page1-container1">
          
            <img
                alt="Company Logo"
                src="/external/image317510-afm-500w.png"
                className="landing-page1-svg1"
            />
            <div className="landing-page1-objective">
            <span className="landing-page1-text04"><span>Contact</span></span>
            <span className="landing-page1-text04"><span>support@xunialabs.com</span></span>
                <span className="landing-page1-text04"><span>+1 (857)-313-2050</span></span>
                <SubscribeForm />
            </div>
        </div>
        <hr className="landing-page1-divider" />
        <span className="landing-page1-text">
            © Copyright 2024 All rights reserved.
        </span>
    </>
);

export default Footer;
