import React from 'react'
import './HeroSection.css'

const HeroSection = () => (
    < >
      <img
        alt="IMAGE43b909a8fb7b9096dbf6a777af57818b7553"
        src="/external/image43b909a8fb7b9096dbf6a777af57818b7553-zofp-1500w.png"
        className="landing-page1-image43b909a8fb7b9096dbf6a777af57818b"
      />
      <span className="landing-page1-text14"><span>Xunia Labs</span></span>
      <span className="landing-page1-text16">
        <span>
          Pioneering AI research ushering a new-age of products with a
          singular aim of propelling humanity to AGI and beyond.
        </span>
      </span>
    </>
  );

  export default HeroSection;