import React, { useRef, useEffect, useState } from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import Products from './Products';
import Values from './Values';
import Objective from './Objective';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import './AllPages.css';

function AllPages() {
    const headerRef = useRef(null);
    const heroRef = useRef(null);
    const productsRef = useRef(null);
    const objectiveRef = useRef(null);
    const valuesRef = useRef(null);
    const footerRef = useRef(null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    const sections = [heroRef, productsRef, objectiveRef, valuesRef, footerRef];

    // Function to scroll to a given ref element
    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',  // Animates the scrolling
                block: 'start'       // Aligns the scrolled-to element at the top of the viewport
            });
            setIsScrolling(true); // Optional: Manage scrolling state
        }
    };

    useEffect(() => {
      const handleScroll = () => {
          const scrollPosition = window.scrollY;
          const windowHeight = window.innerHeight;
          const newIndex = Math.floor(scrollPosition / windowHeight);
          console.log(scrollPosition, windowHeight, newIndex);
          if (newIndex !== activeIndex && newIndex < sections.length) {
              setActiveIndex(newIndex);
              scrollToSection(sections[newIndex]);
          }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, [activeIndex, isScrolling]);

    return (
        <div className="landing-page1-container">
            <Helmet>
                <title>Xunia Labs</title>
            </Helmet>
            
            <div className="landing-page1-landing-page">
                <div className="landing-page1-navigation" ref={headerRef}>
                    <Header sections={{ headerRef, heroRef, productsRef, objectiveRef, valuesRef, footerRef }} />
                </div>
                <div ref={heroRef} className="landing-page1-herosection">
                    <HeroSection />
                </div>
                <div className="landing-page1-container1" ref={objectiveRef}>
                    <Objective />
                </div>
                <div className="landing-page1-values" ref={valuesRef}>
                    <Values />
                </div>
                <div className="landing-page1-products" ref={productsRef}>
                    <Products />
                </div>
                <div className="landing-page1-contact" ref={footerRef}>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default AllPages;
