import React from 'react';
import './SubscribeForm.css'; // Ensure you import your CSS stylesheet

const SubscribeForm = () => {
    return (
        <div className="landing-page1-subscribe">
            <div className="landing-page1-fieldemail">
                <input
                    type="email"
                    placeholder="Email"
                    className="landing-page1-email-input" // New class for styling the input
                />
            </div>
            <button className="landing-page1-btnsubscribe">
                <span className="landing-page1-text10">Submit</span>
            </button>
        </div>
    );
}

export default SubscribeForm;
