import React from 'react'
import './Products.css'
const Products = () => (
    < >
      <span className="landing-page1-text18">Products</span>
      <div className="landing-page1-group999">
        <span className="landing-page1-text20">
          A next-gen app re-imagining information consumption from tech
          news, research to startup updates and more for all stem
          professionals. Experience staying updated with your field without
          infobesity and hassle free. Let our Agents take care of it!
        </span>
        <span className="landing-page1-text21"><span>Try DropOut 1.0</span></span>
        <button className="landing-page1-button"><span className="landing-page1-text23">Sign up for alpha</span></button>
      </div>
      {/* <span className="landing-page1-text24">
        <span>
          Be a part of our progress and gain exclusive beta access & discounted pricing on current/future products. Subscribe today!
        </span>
      </span> */}
    </>
  );
  
export default Products;