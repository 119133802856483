import React from 'react'

import { Helmet } from 'react-helmet'

// import './landing-page1.css'

const LandingPage1 = (props) => {
  return (
    <div className="landing-page1-container">
      <Helmet>
        <title>Xunia Labs</title>
      </Helmet>
      <div className="landing-page1-landing-page">
        <div className="landing-page1-contact">
          <span className="landing-page1-text">
            <span>© Copyright 2024 All rights reserved.</span>
          </span>
          <div className="landing-page1-contactinfo">
            <div className="landing-page1-contactusinformation">
              <span className="landing-page1-text02">
                <span>Contact us</span>
              </span>
              <div className="landing-page1-frame6">
                <div className="landing-page1-frame2">
                  <div className="landing-page1-frame3">
                    <span className="landing-page1-text04">
                      <span>support@xunialabs.com</span>
                    </span>
                    <span className="landing-page1-text06">
                      <span>+1 (857)-313-2050</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="landing-page1-subscribe">
              <div className="landing-page1-fieldemail">
                <span className="landing-page1-text08">
                  <span>Email</span>
                </span>
              </div>
              <div className="landing-page1-btnsubscribe">
                <span className="landing-page1-text10">
                  <span>Submit</span>
                </span>
              </div>
            </div>
          </div>
          <img
            alt="image317510"
            src="/external/image317510-afm-500w.png"
            className="landing-page1-image31"
          />
          <img
            alt="Divider7511"
            src="/external/divider7511-of8a.svg"
            className="landing-page1-divider"
          />
        </div>
        <div className="landing-page1-navigation">
          <img
            alt="logo7550"
            src="/external/logo7550-jlxe-200h.png"
            className="landing-page1-logo"
          />
          <span className="landing-page1-text12">
            <span>Company Products Contact</span>
          </span>
        </div>
        <div className="landing-page1-herosection">
          <img
            alt="IMAGE43b909a8fb7b9096dbf6a777af57818b7553"
            src="/external/image43b909a8fb7b9096dbf6a777af57818b7553-zofp-1500w.png"
            className="landing-page1-image43b909a8fb7b9096dbf6a777af57818b"
          />
          <span className="landing-page1-text14">
            <span>Xunia Labs</span>
          </span>
          <span className="landing-page1-text16">
            <span>
              Pioneering AI research ushering a new-age of products with a
              singular aim of propelling humanity to AGI and beyond.
            </span>
          </span>
        </div>
        <div className="landing-page1-products">
          <span className="landing-page1-text18">
            <span>Products</span>
          </span>
          <div className="landing-page1-group999">
            <span className="landing-page1-text20">
              A next-gen app re-imagining information consumption from tech
              news, research to startup updates and more for all stem
              professionals. Experience staying updated with your field without
              infobesity and hassle free. Let our Agents take care of it!
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="landing-page1-text21">
              <span>Try DropOut 1.0</span>
            </span>
            <button className="landing-page1-button">
              <span className="landing-page1-text23">Sign up for alpha</span>
            </button>
          </div>
          <span className="landing-page1-text24">
            <span>
              Be a part of our progress and gain exclusive beta access &amp;
              discounted pricing on current/future products. Subscribe today!
            </span>
          </span>
          <div className="landing-page1-subscribe1">
            <img
              alt="Rectangle27510"
              src="/external/rectangle27510-79jo-200h.png"
              className="landing-page1-rectangle2"
            />
            <span className="landing-page1-text26">
              <span>Email Address</span>
            </span>
            <div className="landing-page1-button1">
              <span className="landing-page1-text28">
                <span>SUBSCRIBE</span>
              </span>
            </div>
          </div>
        </div>
        <div className="landing-page1-values">
          <div className="landing-page1-valuesimg">
            <img
              alt="Binary0037511"
              src="/external/binary0037511-ig89-300w.png"
              className="landing-page1-binary003"
            />
            <img
              alt="Binary0027511"
              src="/external/binary0027511-uf8-300w.png"
              className="landing-page1-binary002"
            />
            <img
              alt="Binary0017511"
              src="/external/binary0017511-t19-600w.png"
              className="landing-page1-binary001"
            />
            <img
              alt="Binary0047511"
              src="/external/binary0047511-aycm-200h.png"
              className="landing-page1-binary004"
            />
          </div>
          <div className="landing-page1-frame7">
            <span className="landing-page1-text30">
              <span className="landing-page1-text31">Our Values</span>
              <br className="landing-page1-text32"></br>
              <br></br>
              <span>Maximal Utility and access</span>
              <br></br>
              <span>Net Productive outcome</span>
              <br></br>
              <span>Responsible development</span>
            </span>
          </div>
        </div>
        <div className="landing-page1-container1">
          <img
            alt="SVG17511"
            src="/external/svg17511-v82-1400w.png"
            className="landing-page1-svg1"
          />
          <div className="landing-page1-objective">
            <div className="landing-page1-frame71"></div>
            <span className="landing-page1-text39">
              <span className="landing-page1-text40">Objective</span>
              <br className="landing-page1-text41"></br>
              <br className="landing-page1-text42"></br>
              <span className="landing-page1-text43">
                Xunia Labs is a collective of round pegs in the square holes who
                believe that AGI cannot be realized and accelerate the human
                race by scaling alone. We believe that to propel humanity from
                the precipice of the new AI age into the dream of AGI, better
                products &amp; frontier AI systems must be built for the
                anthropic experience. Because after all, a revolutionary
                technology is as good as an experience it delivers.
              </span>
              <br className="landing-page1-text44"></br>
              <br className="landing-page1-text45"></br>
              <span className="landing-page1-text46">
                We build everything that AI isn’t …
              </span>
              <span> yet.</span>
            </span>
          </div>
          <span className="landing-page1-text48">
            <span>Company</span>
          </span>
        </div>
        {/* <button className="landing-page1-button2">
          <span className="landing-page1-text50">
            <span>Pressed</span>
          </span>
        </button> */}
      </div>
    </div>
  )
}

export default LandingPage1
