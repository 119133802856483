import React from 'react'
import './Objective.css'
const Objective = () => (
    < >
          <span className="landing-page1-text48">
      <span>Company</span>
    </span>
    <img
      alt="SVG17511"
      src="/external/svg17511-v82-1400w.png"
      className="landing-page1-svg1"
    />
    <div className="landing-page1-objective">
      <span className="landing-page1-text39">
        <span className="landing-page1-text40">Objective</span>
        <br className="landing-page1-text41"></br>
        <br className="landing-page1-text42"></br>
        <span className="landing-page1-text43">
          Xunia Labs is a collective of round pegs in the square holes who
          believe that AGI cannot be realized and accelerate the human
          race by scaling alone. We believe that to propel humanity from
          the precipice of the new AI age into the dream of AGI, better
          products &amp; frontier AI systems must be built for the
          anthropic experience. Because after all, a revolutionary
          technology is as good as an experience it delivers.
        </span>
        <br className="landing-page1-text44"></br>
        <br className="landing-page1-text45"></br>
        <span className="landing-page1-text46">
          We build everything that AI isn’t …
        </span>
        <span> yet.</span>
      </span>
    </div>
  
  </>
  );
  
    export default Objective;